<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <b-modal
        id="modal-create"
        cancelTitle="Cancel"
        centered

        ok-title="Create"
        title="Create Currency"
        @ok.prevent="createCurrency"
    >
      <validation-observer ref="createCurrencyForm" class="row">
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-name"
            rules="required"
        >
          <b-form-group
              label="Name"
              label-for="currency-name"
          >
            <b-form-input
                id="currency-name"
                v-model="currency.name"
                :state="errors.length > 0 ? false:null"
                name="currency-name"
                placeholder="currency name..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-code"
            rules="required"
        >
          <b-form-group
              label="code"
              label-for="currency-code"
          >
            <b-form-input
                id="currency-code"
                v-model="currency.code"
                :state="errors.length > 0 ? false:null"
                name="currency-code"
                placeholder="currency code..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-exchange-rate"
            rules="required"
        >
          <b-form-group
              label="exchange-rate"
              label-for="currency-exchange-rate"
          >
            <b-form-input
                id="currency-exchange-rate"
                v-model="currency.exchangeRate"
                :state="errors.length > 0 ? false:null"
                name="currency-name"
                placeholder="exchange rate..."
                type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="symbol"
            rules="required"
        >
          <b-form-group
              label="symbol"
          >
            <b-form-input
                id="symbol"
                v-model="currency.symbol"
                :state="errors.length > 0 ? false:null"
                maxlength="1"
                name="symbol"
                placeholder="symbol..."
                type="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <b-col class="my-1" cols="12">
          <b-form-group
              label="Currency Flag"
          >
            <MediaHandler ref="mediaHandler" @sendData="setFiles"></MediaHandler>

          </b-form-group>

        </b-col>
      </validation-observer>
    </b-modal>
    <b-modal
        id="modal-update"
        cancelTitle="Cancel"
        centered
        ok-title="Update"

        title="Update Currency"
        @ok.prevent="updateCurrency"
    >
      <validation-observer v-if="selectedCurrency" ref="updateCurrencyForm" class="row">
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-name"
            rules="required"
        >
          <b-form-group
              label="Name"
              label-for="currency-name"
          >
            <b-form-input
                id="currency-name"
                v-model="selectedCurrency.name"
                :state="errors.length > 0 ? false:null"
                name="currency-name"
                placeholder="currency name..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-code"
            rules="required"
        >
          <b-form-group
              label="code"
              label-for="currency-code"
          >
            <b-form-input
                id="currency-code"
                v-model="selectedCurrency.code"
                :state="errors.length > 0 ? false:null"
                name="currency-code"
                placeholder="currency code..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="currency-exchange-rate"
            rules="required"
        >
          <b-form-group
              label="exchange-rate"
              label-for="currency-exchange-rate"
          >
            <b-form-input
                id="currency-exchange-rate"
                v-model="selectedCurrency.exchangeRate"
                :state="errors.length > 0 ? false:null"
                name="currency-name"
                placeholder="currency name..."
                type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="symbol"
            rules="required"
        >
          <b-form-group
              label="symbol"
          >
            <b-form-input
                id="symbol"
                v-model="selectedCurrency.symbol"
                :state="errors.length > 0 ? false:null"
                maxlength="1"
                name="symbol"
                placeholder="symbol..."
                type="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <b-col v-if="selectedCurrency.flag" class="my-1" cols="12">
          <b-form-group
              label="Main Picture"
          >
            <div class="">
              <div
                  class=" position-relative   border rounded  shadow     "
                  style="height: 10rem;width: 10rem"
              >
                <img :src="selectedCurrency.flag" alt="" style="object-fit: contain;height: 10rem;width: 10rem">
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col class="my-1" cols="12">
          <b-form-group
              label="Currency Flag"
          >
            <MediaHandler ref="mediaHandlerUpdate" @sendData="setFilesUpdate"></MediaHandler>

          </b-form-group>

        </b-col>
      </validation-observer>
    </b-modal>
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        title="Delete Currency"
        @ok.prevent="deleteCurrency"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this currency ?</span>
        </div>
      </div>
    </b-modal>

    <div v-if="currencies">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <h1>Currencies</h1>

        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-start mt- mt-md-0"
                md="2"
            >
              <b-button
                  v-b-modal.modal-create
                  class="w-100 mb-lg-0 mt-1 "
                  disabled
                  variant="success"
              >
                Create Currency
              </b-button>
            </b-col>
            <!--            <b-col-->
            <!--                md="3" class="   mb-1 mb-md-0"-->
            <!--            >-->
            <!--              <b-form-group label="count">-->
            <!--              <v-select-->
            <!--                  v-model="perPage"-->
            <!--                  :clearable="false"-->
            <!--                  :options="perPageOptions"-->
            <!--                  class="per-page-selector w-75 mx-50"-->
            <!--              />-->
            <!--              </b-form-group>-->
            <!--            </b-col>-->


          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="currencies"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            striped
        >


          <template #cell(operation)="data">
            <div class="d-flex align-items-center  w-100">
              <span v-b-modal.modal-update class="cursor-pointer mx-1" @click="setSelectedCurrency(data.item)">

              <feather-icon
                  class="text-primary"
                  icon="EditIcon"
                  size="20"
              />
              </span>
              <!--              <span v-b-modal.modal-delete class="cursor-pointer mx-1" @click="setSelectedCurrency(data.item)">-->
              <!--              <feather-icon-->
              <!--                  class="text-danger"-->
              <!--                  icon="TrashIcon"-->
              <!--                  size="20"-->
              <!--              />-->
              <!--              </span>-->
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Helper from '@/libs/Helper'
import { CreateCurrency, DeleteCurrency, GetAllCurrencies, UpdateCurrency } from '@/libs/Api/Currencies'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'

export default {
  title: 'manage-currencies',
  name: 'manage-currencies',
  data() {
    return {
      required,
      currencies: null,
      totalCount: null,
      showOverlay: false,
      currentPage: 1,
      currency: {
        name: '',
        exchangeRate: null,
        code: '',
        symbol: '',
        FlagFile: null,
      },
      baseURL: Helper.baseUrl + 'media/gallery/profile/',
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'id',
          label: '#'
        },
        {
          key: 'name',
          label: 'name'
        },
        // {
        //   key: 'exchangeRate',
        //   label: 'exchange rate'
        // },
        {
          key: 'code',
          label: 'currency code'
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      pageNumber: 1,
      selectedCurrency: null,
    }
  },
  async created() {
    await Promise.all([
      this.getCurrencies()
    ])
  },
  watch: {
    currentPage: function () {
      this.getCurrencies()
    },
    perPage: function () {
      this.getCurrencies()
    }
  },
  methods: {
    setFiles(file) {
      this.currency.FlagFile = file
    },
    setFilesUpdate(file) {
      if (file) {
        this.selectedCurrency['FlagFile'] = file
      }

    },
    setSelectedCurrency(item) {
      this.selectedCurrency = JSON.parse(JSON.stringify(item))
    },
    async getCurrencies() {
      let _this = this
      _this.showOverlay = true
      let getAllCurrencies = new GetAllCurrencies(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
      }
      getAllCurrencies.setParams(data)
      await getAllCurrencies.fetch(function (content) {
        _this.currencies = content.data.getCurrencies
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async createCurrency() {
      let _this = this
      Helper.validateForm(_this, 'createCurrencyForm', async () => {
        _this.showOverlay = true
        let createCurrency = new CreateCurrency(_this)
        _this.$refs.mediaHandler.sendFiles()
        let myForm = await Helper.makeFormData(_this.currency)
        createCurrency.setParams(myForm)
        await createCurrency.sendFormData(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `currency successfully created`,
            },
          })
          _this.currency = {
            name: '',
            exchangeRate: null,
            code: ''
          }
          _this.getCurrencies()
          _this.showOverlay = false
          _this.$bvModal.hide('modal-create')

        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },
    async updateCurrency() {
      let _this = this
      Helper.validateForm(_this, 'updateCurrencyForm', async () => {
        _this.showOverlay = true
        let updateCurrency = new UpdateCurrency(_this)
        _this.$refs.mediaHandlerUpdate.sendFiles()
        let myForm = await Helper.makeFormData(_this.selectedCurrency)
        updateCurrency.setParams(myForm)
        await updateCurrency.sendFormData(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `currency successfully updated`,
            },
          })
          _this.getCurrencies()
          _this.selectedCurrency = null
          _this.$bvModal.hide('modal-update')
          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })

    },
    async deleteCurrency() {
      let _this = this
      _this.showOverlay = true
      let deleteCurrency = new DeleteCurrency(_this)
      deleteCurrency.setParams({ id: _this.selectedCurrency.id })
      await deleteCurrency.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `currency successfully deleted`,
          },
        })
        _this.getCurrencies()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },

  },

  components: {
    MediaHandler,
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>
